
import { defineAsyncComponent, PropType } from "vue";
import { Vue, Options } from "vue-class-component";
import { ALLOWED } from "@/app/infrastructures/misc/RolePermission";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import { AccountController } from "@/app/ui/controllers/AccountController";
import {
  formatDateWithoutTime,
  formatPrice,
} from "@/app/infrastructures/misc/Utils";
import { BalanceController } from "@/app/ui/controllers/BalanceController";
const ModalTabTopupVa = defineAsyncComponent({
  loader: () =>
    import("@/app/ui/views/balance/component/modal-tab-topup-va.vue"),
});
const ModalTabTopupManual = defineAsyncComponent({
  loader: () =>
    import("@/app/ui/views/balance/component/modal-tab-topup-manual.vue"),
});
const ModalDetailCashback = defineAsyncComponent({
  loader: () =>
    import("@/app/ui/views/balance/component/modal-detail-cashback.vue"),
});

@Options({
  components: {
    ModalTabTopupVa,
    ModalTabTopupManual,
    ModalDetailCashback,
  },
  props: {
    onClose: {
      default: Function as PropType<() => void>,
      type: Function,
    },
  },
})
export default class ModalTopup extends Vue {
  // Tabs
  tabs = [
    {
      name: "va",
      title: "Transfer Virtual Account",
      permission: ALLOWED,
    },
    {
      name: "manual",
      title: "Transfer Bank",
      permission: ALLOWED,
    },
  ];

  get filteredTabs() {
    return this.tabs.filter((tab: any) => {
      return checkRolePermission(tab.permission);
    });
  }

  activeTab = "va";
  changeTabs(value: string) {
    this.activeTab = value;
  }

  get activeCashback() {
    return AccountController.activeCashback;
  }

  get startDateCashback() {
    return formatDateWithoutTime(
      new Date(this.activeCashback.data.ccbStartDate)
    );
  }
  get dataTiers() {
    return this.activeCashback.data.ccbTiers;
  }
  get endDateCashback() {
    return formatDateWithoutTime(new Date(this.activeCashback.data.ccbEndDate));
  }
}
